import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from '../hooks';
import { Form, Input, TextArea } from './FormElements';

export default function ContactForm({ primary, secondary }) {
  const { state, handleSubmit, handleChange } = useForm({
    name: '',
    email: '',
    tel: '',
    message: '',
  });

  console.log(state);

  return (
    <Form
      onSubmit={handleSubmit}
      handleChange={handleChange}
      name="contact"
      className="w-full mt-4 font-display"
    >
      {/* Name */}
      <Input
        handleChange={handleChange}
        label="Name"
        name="name"
        primary={primary}
        required
        type="text"
        value={state.name}
      />

      {/* Email address */}
      <Input
        handleChange={handleChange}
        label="Email address"
        name="email"
        primary={primary}
        required
        type="email"
        value={state.email}
      />

      {/* Phone number */}
      <Input
        handleChange={handleChange}
        label="Phone number"
        name="tel"
        primary={primary}
        required
        type="tel"
        value={state.tel}
      />

      {/* Message */}
      <TextArea
        handleChange={handleChange}
        label="Message"
        name="message"
        primary={primary}
        required
        value={state.message}
      />

      <div className="flex items-center justify-between mb-6">
        {/* Submit */}
        <button
          className={`bg-${primary} hover:bg-${secondary} cursor-pointer font-bold leading-none px-4 py-2 text-white text-xs tracking-wider uppercase`}
          type="submit"
        >
          Send
        </button>
      </div>
    </Form>
  );
}

ContactForm.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};
