import React from 'react';
import PropTypes from 'prop-types';

import Navigation from './Navigation';
import Footer from './Footer';

const Layout = ({ children, primary }) => (
  <div className="antialiased flex flex-col font-sans leading-relaxed min-h-screen text-gray-700">
    <Navigation primary={primary} />
    <main id="main" className="flex flex-1 flex-col mx-auto w-full">
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.node,
};

export default Layout;
