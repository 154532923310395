import React from 'react';
import Headroom from 'react-headroom';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Logo from '../images/assets/logo-dark.svg';

const Navigation = ({ primary = `blue-600`, secondary = `blue-500` }) => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      site {
        siteMetadata {
          email
          facebook
          instagram
          phone
          phoneFormatted
          title
        }
      }
    }
  `);
  return (
    <Headroom>
      <article
        id="navigation"
        className="bg-white border-b border-gray-200 font-display leading-none sticky top-0 w-full z-40"
      >
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="flex font-bold items-center no-underline px-8 py-4 text-lg tracking-tight"
            >
              <img
                src={Logo}
                className="w-32"
                alt={data.site.siteMetadata.title}
              />
            </Link>

            <button
              className="flex sm:hidden items-center justify-center p-4"
              onClick={() => {
                const nav = document.getElementById('nav');
                nav.classList.toggle('hidden');
              }}
              type="button"
            >
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <nav
            id="nav"
            className="hidden sm:flex sm:items-center sm:ml-auto px-4 relative w-full sm:w-auto"
          >
            <NavLink
              link="/"
              title="Home"
              primary={primary}
              secondary={secondary}
            />
            <NavLink
              link="/about"
              title="About"
              primary={primary}
              secondary={secondary}
            />
            <NavLink
              link="/services"
              title="Services"
              primary={primary}
              secondary={secondary}
            />
            <NavLink
              link="/projects"
              title="Projects"
              primary={primary}
              secondary={secondary}
            />
            <NavLink
              link="/contact"
              title="Contact"
              primary={primary}
              secondary={secondary}
            />
          </nav>
          <div className="hidden lg:flex lg:items-center pr-8">
            <a
              className="hidden md:block ml-3 text-lg hover:text-pink-600"
              href={data.site.siteMetadata.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              className="hidden md:block ml-1 text-lg hover:text-blue-600"
              href={data.site.siteMetadata.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare />
            </a>
            <p className="flex-shrink-0 md:ml-6 w-full sm:w-auto">
              <a
                className="bg-blue-600 font-semibold inline-block leading-snug px-3 py-1 text-sm text-white uppercase"
                href={`tel:${data.site.siteMetadata.phoneFormatted}`}
              >
                Call: {data.site.siteMetadata.phoneFormatted}
              </a>
            </p>
          </div>
        </div>
      </article>
    </Headroom>
  );
};

Navigation.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

export default Navigation;

const NavLink = ({ link, title, primary, secondary }) => (
  <Link
    to={link}
    activeClassName={`text-${primary}`}
    className={`block border-t sm:border-none font-semibold p-4 hover:text-${secondary} text-sm tracking-wide uppercase`}
  >
    {title}
  </Link>
);

NavLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
};
