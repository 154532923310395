import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Map from './Map';
import Form from './Form';

const Contact = ({ primary = `blue-400`, secondary = `blue-300` }) => {
  const data = useStaticQuery(graphql`
    query ContactComponentQuery {
      site {
        siteMetadata {
          address
          googleMapsAddress
        }
      }
    }
  `);
  return (
    <div id="contact" className="max-w-6xl mx-auto px-8 py-12 w-full">
      <div className="flex flex-wrap -mx-4 pb-12 md:pb-0">
        <div className="mb-4 md:pb-12 px-4 w-full md:w-1/2">
          <h2
            className={`font-bold font-display leading-none mb-4 md:mt-4 text-4xl text-${primary} uppercase`}
          >
            Contact Us
          </h2>
          <p className="mb-4">
            If you would like a quote please email us using the contact form
            below. We will get back to you as soon as we can.
          </p>
          <p
            className={`font-semibold mb-6 text-${primary} hover:text-${secondary} hover:underline`}
          >
            <a
              href={data.site.siteMetadata.googleMapsAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.site.siteMetadata.address}
            </a>
          </p>
          <Map />
        </div>
        <div className="mb-4 px-4 w-full md:w-1/2">
          <h3
            className={`font-bold font-display leading-none mb-4 mt-8 md:mt-4 text-2xl text-${primary} uppercase`}
          >
            Enquiries
          </h3>
          <Form primary={primary} secondary={secondary} />
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

export default Contact;
