const defaultTheme = require('tailwindcss/defaultTheme');
const tailwindcssAspectRatio = require('tailwindcss-aspect-ratio');

module.exports = {
  purge: false,
  theme: {
    aspectRatio: {
      square: [1, 1],
    },
    extend: {
      fontFamily: {
        display: ['Poppins', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        'transparent-black': 'hsla(0, 0%, 0%, .6666)',
        gray: {
          '100': '#f7f7f7',
          '200': '#e9e9e9',
          '300': '#cbcbcb',
          '400': '#b5b5b5',
          '500': '#9f9f9f',
          '600': '#727472',
          '700': '#5d5e5e',
          '800': '#474749',
          '900': '#363435',
        },
        blue: {
          '100': '#daf5ff',
          '200': '#a0e6ff',
          '300': '#65d7ff',
          '400': '#28b9ec',
          '500': '#1795d3',
          '600': '#1a82c5',
          '700': '#0e669e',
          '800': '#054b78',
          '900': '#003151',
        },
        pink: {
          '100': '#f7edf3',
          '200': '#eec0dc',
          '300': '#e596c5',
          '400': '#dc6eb0',
          '500': '#d3499c',
          '600': '#ca278a',
          '700': '#b8247d',
          '800': '#971d67',
          '900': '#70164c',
        },
      },
    },
  },
  variants: { opacity: ['responsive', 'hover'] },
  plugins: [tailwindcssAspectRatio],
};
