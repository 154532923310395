import React from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Logo from '../images/assets/logo-light.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          address
          googleMapsAddress
          email
          facebook
          instagram
          phone
          phoneFormatted
          title
        }
      }
    }
  `);

  return (
    <footer
      id="footer"
      className="text-sm text-white uppercase bg-gray-800 font-display"
    >
      <div className="px-10 py-8">
        <div className="flex flex-wrap items-center justify-center w-full max-w-6xl mx-auto sm:justify-start">
          <Link to="/" className="w-1/2 mb-4 sm:w-1/3">
            <img
              className="w-full sm:pr-4"
              style={{ maxWidth: `256px` }}
              src={Logo}
              alt="Morr logo."
            />
          </Link>
          <div className="flex flex-col w-full text-center lg:flex-row sm:text-right sm:w-2/3">
            <div className="w-full lg:px-4 lg:w-1/2">
              <div className="-mx-2">
                <nav className="flex justify-end max-w-xs mx-auto mb-4 font-bold sm:mr-0 lg:mx-auto">
                  <Link activeClassName="text-blue-300" className="px-2" to="/">
                    Home
                  </Link>
                  <Link
                    activeClassName="text-blue-300"
                    className="px-2"
                    to="/about"
                  >
                    About
                  </Link>
                  <Link
                    activeClassName="text-blue-300"
                    className="px-2"
                    to="/services"
                  >
                    Services
                  </Link>
                  {/* <Link activeClassName="text-blue-300" className="px-2" to="/projects">Projects</Link> */}
                  <Link
                    activeClassName="text-blue-300"
                    className="px-2"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </nav>
              </div>
            </div>
            <div className="lg:w-1/2">
              <p className="mb-4">
                <span className="font-bold">Address:</span>
                <a
                  className="inline-block"
                  href={data.site.siteMetadata.googleMapsAddress}
                >
                  &nbsp;{data.site.siteMetadata.address}
                </a>
              </p>
              <p className="mb-4">
                <span className="font-bold">Contact:</span>
                <a
                  className="inline-block"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  &nbsp;{data.site.siteMetadata.email}
                </a>
              </p>
              <p className="flex items-center justify-center sm:justify-end">
                Connect with us{' '}
                <a
                  className="ml-3 text-lg"
                  href={data.site.siteMetadata.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  className="ml-1 text-lg"
                  href={data.site.siteMetadata.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="p-2 text-xs leading-none text-center bg-gray-600">
        <a
          className="inline-flex px-1 font-semibold"
          href="https://pd.design"
          target="_blank"
          rel="noopener noreferrer"
        >
          Designed and developed by PD {new Date().getFullYear()}
        </a>
      </p>
    </footer>
  );
};

export default Footer;
