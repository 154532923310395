import PropTypes from 'prop-types';
import React from 'react';

export function Input({
  handleChange,
  label,
  name,
  primary,
  required = true,
  type = 'text',
  value,
}) {
  return (
    <div className="mb-6">
      <label className="flex flex-col" htmlFor={name}>
        <span className="mb-2 text-xs font-bold tracking-wider uppercase">
          {label}:
        </span>
        <input
          className={`appearance-none bg-white border border-gray-200 hover:bg-gray-100 leading-tight px-3 py-2 rounded-none text-gray-700 w-full focus:outline-none focus:border-${primary}`}
          id={name}
          name={name}
          onChange={handleChange}
          required={required}
          type={type}
          value={value}
        />
      </label>
    </div>
  );
}

Input.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};
